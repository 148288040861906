import React, {useEffect, useState} from 'react'
import {graphql, Link, navigate} from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layoutSecure'
import Container from '../components/container'
import {toPlainText} from '../lib/helpers'
import PortableText from '../components/portableText'
import MainReference from '../components/mainReference'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import Accordion from '../components/accordion'
import {isUserInRegion, isUserInRegionOkta} from '../services/auth'
import RestrictContentError from '../components/restrict-content-error'
import { useSelector, useDispatch } from "react-redux"


export const query = graphql`
  query SiteTemplateQuery($id: String!) {
    page: sanitySite(id: {eq: $id}) {
      _id
      _key
      _type
      banner {
        alt
        asset{
          url
        }
      }
      title
      slug {
        current
      }
      publishedAt
      pageImage {
        alt
        ...SanityImage
      }
      _rawPageDescription(resolveReferences: {maxDepth: 5})
      mainReferences {
        ...SanityFile
        ...SanityLink
      }
      categories {
        title
        region
        _key
        _type
        _rawDecription(resolveReferences: {maxDepth: 5})
        subSections {
          ...SanityFile
          ...SanityLink
          ...SanityCommonSection
        }
      }
      region
    }

    setting: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`

const SiteTemplate = props => {

  const {data, errors} = props
  // console.log('-----------> data: ', data)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = (data || {}).page

  if (!page) {
    throw new Error(
      'Missing "site detail page". Open the studio at http://localhost:3333 and add some content to "site detail page" and restart the development server.'
    )
  }

  let  banner = page.banner && page.banner.asset ? page.banner.asset.url : ''
  let setting = (data || {}).setting

  const userInfo = useSelector(state => state.userInfo)

  let userRegion = userInfo ? userInfo.region : ''

  if(!isUserInRegion(userRegion, page.region)){
    return (
        <Layout>
            <Container>
                <RestrictContentError />
            </Container>
        </Layout>
    )
  }


  return (
    <Layout>
      {/* {errors && <SEO title='GraphQL Error' />} */}
      {/* {post && <SEO title={post.title || 'Untitled'} description={toPlainText(post._rawExcerpt)} image={post.mainImage} />} */}

      {page && <SEO title={page.title || 'Untitled'} description={toPlainText(page._rawPageDescription) || setting.description} />}


      {/* image */}
      <div className='container container-adjust-p'>
      {
        banner ? 
        <div>
            <img src={banner} alt={page.banner ?page.banner.alt : ''} className='d-none d-sm-block' style={{margin:'0 auto', width:'100%'}} />
        </div>
        : 
        <div className="bg-brown d-none d-sm-flex" style={{backgroundImage: 'url(' + banner + ')', backgroundPosition: 'center center', backgroundSize: 'cover', width: '100%', height: '180px', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        </div>
      }
      <div className="bg-brown d-flex d-sm-none py-4" style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column', minHeight:100 }}> 
          {/* <div className="container">
              <div className="row">
                  <h1 className="mb-0" style={{color: 'rgb(245, 235, 220)'}}>{page.title.toUpperCase() || ''}</h1>
              </div>
          </div> */}
      </div>
      </div>


      {/* breadcrumb */}
      <section className="p-0">
        <div className="container">
          <div className="row">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item"><Link to="/site">Site</Link></li>
                <li className="breadcrumb-item active" aria-current="page">{page.title || ''}</li>
                </ol>
            </nav>
          </div>
        </div>
      </section>

      {/* content */}
      <section className="pt-0 pb-0 mb-4">
        <div className="container">
          <div className="row d-flex justify-content-lg-between">

            {page.pageImage && page.pageImage.asset && (
              <div className="col-md-6">
                <img
                  src={imageUrlFor(buildImageObj(page.pageImage))
                    .fit('crop')
                    .url()}
                  alt={page.pageImage.alt}
                />
              </div>
            )}

            {/* <div className="col-md-6"> */}
            <div className={(page.pageImage && page.pageImage.asset) ? "col-md-6" : "col-md-12"}>
              <h3>{page.subTitle}</h3>
              {page._rawPageDescription && <PortableText blocks={page._rawPageDescription} />}

              {page.mainReferences && <MainReference items={page.mainReferences} userRegion={userRegion} />}
            </div>
          </div>
        </div>
      </section>


      {/* accordion */}
      <section className="pt-0 pb-0 mb-4">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-12">                            
              <Accordion items={page.categories} userRegion={userRegion} />
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

export default SiteTemplate
